import React from "react"
import { Layout } from "../components/Layout"
import { Helmet } from "react-helmet"
import {
  CTAButton,
  AttributeSection,
  GetStartedButton,
  LearnMoreButton,
  Faq,
  CompareSection
} from "../commonComponents"
import {
  IntegrationsMiddleSectionImageLeft,
  IntegrationsMiddleSectionImageRight,
} from "../integrationsMiddleSection"
import {
  IntegrationDarkSection,
  IntegrationHeroSection,
} from "../integrationsUtils"
import { capitalizeFirstLetter } from "../utils"

export default function Freshworks() {
  const SERVICE_NAME = "freshworks"
  const BACKGROUND_SRC = `/img/${SERVICE_NAME}-bg.png`
  const BACKGROUND_WEB_POSITION = "100% 70px"
  const BACKGROUND_MOBILE_POSITION = "50% 50px"
  // It is done like this because the content is different in almost every page.
  return (
    <Layout
      keywords="freshworks integration, Freshdesk and Jira integration, Freshworks chatbot, Freshworks AI, Freshworks Agent Assist"
      title="Elevate Freshworks Operations with Agent Assist AI and GPT Chatbot"
      description="Discover how proactive AI can transform your Freshworks operations. Boost efficiency, improve customer support & stay ahead of the curve with innovative AI technology"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="canonical" href="https://irisagent.com/freshworks/" />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <IntegrationHeroSection
        serviceName={SERVICE_NAME}
        titleIntegration={
          <>
            Up level your <br />
            <span className="title-fucsia-color">
              {capitalizeFirstLetter(SERVICE_NAME)}<br/> operations
            </span>
          </>
        }
        descriptionIntegration={
          <>
            <span>
              Resolve support tickets faster,
              <br/>improve agent performance, and deflect
              <br/>more than 40% of tickets with IrisGPT
              <br/>chatbot and Freshworks integration
            </span>
          </>
        }
        sectionBackgroundSrc={BACKGROUND_SRC}
        sectionBackgroundWebPosition={BACKGROUND_WEB_POSITION}
        sectionBackgroundMobilePosition={BACKGROUND_MOBILE_POSITION}
      />
      {/* <!-- End Hero --> */}

      {/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Scale your{" "}
            <span className="title-fucsia-color">customer support team</span>
          </span>
        }
        button={
          <CTAButton
            to="https://www.freshworks.com/apps/irisagent/"
            label={`FIND US ON ${SERVICE_NAME.toUpperCase()} MARKETPLACE`}
            target="_blank"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}

      {/* <!-- ======= Middle Section ======= --> */}

      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/freshworksMiddle1.webp"
        imageTextMiniature="/img/freshworksMiniature.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={<>Resolve common tickets automatically with AI</>}
        descriptionTextContent={
          <>
            Access appropriate resolutions based on support ticket data and
            knowledge base articles within Freshworks. Resolve common support tickets using AI and
            IrisGPT to provide instant solutions to customers.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/irisgpt.svg"
        imageTextMiniature="/img/freshworksMiniature.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>Deflect 40% of cases and chats with Freshworks AI and IrisGPT Chatbot integration</>
        }
        descriptionTextContent={
          <>
            Trained on the world's largest large language models, IrisGPT generates multilingual, trustworthy, and highly accurate answers integrated in your Freshworks chatbot
          </>
        }
      />
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/freshworksMiddle3.webp"
        imageTextMiniature="/img/freshworksMiniature.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>Automate tagging, routing and prioritizing within Freshworks</>
        }
        descriptionTextContent={
          <>
            IrisAgent discovers tags customized to your domain and automatically
            tags new tickets. Get rid of manual and inaccurate ticket tagging. Generate ai
            sentiment analysis for Freshworks tickets. Route tickets to the right team and prioritize.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/freshworksMiddle2.webp"
        imageTextMiniature="/img/freshworksMiniature.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>Add product context from DevOps tools to Freshworks tickets</>
        }
        descriptionTextContent={
          <>
            Get to know customers’ business priorities and usage history to
            better-empathize with their experiences. Leverage internal
            operational and employee support data and product signals to instantly
            discover the source of user issues and link them with known product bugs in Jira.
          </>
        }
      />

      {/* <!-- End Middle Section --> */}
      {/* <!-- ======= Counts Section ======= --> */}
      <CompareSection theme="dark" service="Freshworks"/>
      {/* <!-- End Counts Section --> */}


      {/* //   <!-- ======= Attribute Section ======= --> */}
      <AttributeSection />
      {/* //   <!-- ======= END -- Attribute Section ======= --> */}

      <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="How does IrisAgent enhance customer service for Freshworks clients?"
                  answer="IrisAgent enhances customer service by providing fast, accurate, and personalized responses across various communication channels. Its advanced capabilities enable it to comprehend human language nuances, ensuring a seamless and cohesive brand experience for Freshworks clients and their customers."
                />
                <Faq
                  id="faq2"
                  question="Can IrisAgent integrate with Freshworks' existing AI capabilities?"
                  answer="Yes, IrisAgent seamlessly integrates with Freshworks solutions platform, complementing its existing AI capabilities. Through this integration, Freshworks clients can leverage IrisAgent to enhance their customer engagement strategies and drive operational efficiency."
                />
                <Faq
                  id="faq3"
                  question="How does IrisAgent AI resolve support tickets in Freshworks faster?"
                  answer="IrisAgent AI and IrisGPT in Freshworks saves agents' time and effort by automating responses to common, repetitive support queries. It also provides agents with real-time, context-aware suggestions, enabling them to resolve complex issues faster and more accurately. This results in improved customer satisfaction and reduced ticket resolution times."
                />
                <Faq
                  id="faq4"
                  question="How does IrisAgent AI automate ticket tagging, routing, and sentiment analysis in Freshworks?"
                  answer="IrisAgent automatically identifies tags specific to your domain or industry and applies them to new tickets without agent intervention. Our AI also analyzes Freshdesk tickets for language cues that reveal the sentiment. IrisAgent routes tickets to the right agent based on their domain and expertise. It uses built-in analytics to identify those product features with the most negative reactions so that support agents and product teams can better understand the source of the most 'how-to' tickets."
                />
                <Faq
                  id="faq5"
                  question="How can Freshworks clients get started with IrisAgent?"
                  answer="It takes just a few minutes to get started with IrisAgent. Freshworks clients can install the IrisAgent app from the Freshworks marketplace and start using it right away. IrisAgent is designed to be user-friendly and intuitive, so you can start leveraging its advanced AI capabilities without any technical expertise."
                />
                <Faq
                  id="faq6"
                  question="What channels does IrisAgent support for communication?"
                  answer="IrisAgent supports multiple communication channels, including email, chat, and social media platforms. Its omnichannel approach enables Freshworks clients to engage with their customers wherever they are, providing a unified experience across all channels."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End Cta Section --> */}
      {/* <!-- ======= Cta Section ======= --> */}
      {/* <ContactSection></ContactSection> */}
      {/* <!-- End Cta Section --> */}
    </Layout>
  )
}
